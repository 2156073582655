$green:   #009844;
$pagination-color: $green;
$pagination-active-bg: $green;
@import "~bootstrap/scss/bootstrap";

body {
  background-color:#F2F2F2;
  min-width: 1280px;
  font-family: Noto Sans CJK JP;
}
// .nav-pills .nav-link.active{
//   background-color: #c5c6c7;
// }

.tooltip-inner {
  font-size: 1rem;
}

// 参考: https://chepirare.com/css/marquee/
//.marquee {
//  width: 100%;
//  overflow: hidden;
//}
// 
//.marquee p {
//  @extend .text-nowrap;
//  @extend .my-1;
//  animation: marquee 30s linear infinite;
//}
//
//@keyframes marquee {
//  0% { transform: translateX(0) }
//  100% { transform: translateX(-100%) }
//}

.text-pre-wrap {
  white-space: pre-wrap;
}

.notice {
  background : #7E8D8D !important;
  font-size : 16px;
  color : #FFFFFF;  
}

.header {
  background-color:#ffffff ;
  border-bottom: 2px solid #cccccc ;
  .menu {
    color: $green;
    font-size : 22px;
  }
}

.sheet {
  background : #FFFFFF;
  border-radius : 12px 12px 28px 28px;
  -moz-border-radius : 12px 12px 28px 28px;
  -webkit-border-radius : 12px 12px 28px 28px;
  box-shadow : 0px 0px 30px rgba(37, 104, 42, 0.15);
  filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=0, Color='#25682A') ;  
}

.navbar.fixed-bottom {
  background : #323E3E;

  table {
    border-spacing: 0.3rem;
    border-collapse:separate;
  }  

  a {
    font-size : 14px;
    color : #B6B6B6;    
    text-decoration: none;
  }

  .copy-right {
    font-size : 14px;
    color : #5F6E6E;
  }  
}

.btn-secondary {
  background : #E6E6E6;
  border-radius : 31px;
  box-shadow : 0px 5px 0px rgba(177, 177, 177, 1);
  filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=5, Color='#B1B1B1') ;
  font-size : 22px;
  color : #4D4D4D;
  border-color: transparent;

  padding: 1rem 3rem 1rem 3rem;

  &.btn-lg {
    border-radius : 37px;
    -moz-border-radius : 37px;
    -webkit-border-radius : 37px;
    font-size : 24px;  
  }
}

.btn-success {
  background : $green;
  border-radius : 31px;
  box-shadow : 0px 5px 0px rgba(0, 126, 49, 1);
  filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=5, Color='#007E31') ;
  font-size : 22px;
  color : #FFFFFF;
  border-color: transparent;

  padding: 1rem 3rem 1rem 3rem;

  &.btn-lg {
    border-radius : 37px;
    -moz-border-radius : 37px;
    -webkit-border-radius : 37px;
    font-size : 24px;  
  }
}

.form-check-input {
  width: 1.5rem;
  height: 1.5rem;
  border-style : Solid;
  border-color : #CCCCCC;
  border-width : 2px;
  &:checked {
    background: $green;
    border-color : $green;
  }
}

.search {
  background : #F2F2F2 !important;
  border-radius : 10px !important;
  padding: 1.5rem !important;

  .input-group {
    background : #FFFFFF !important;
    border-radius : 31px !important;
    font-size : 20px !important;
    color : #B3B3B3 !important;      
    padding: 0.5rem 1rem !important;

    .input-group-prepend {
      color : $green !important;
      font-size : 24px !important;
    }

    .form-control {
      border: 0 !important;
    }

    input:focus {
      box-shadow: none;
    }
  }
}

.align-middle {
  vertical-align: middle !important;

}

.d-flex-center-label .form-check-label {
  display: flex;
  align-items: center;
}